import {
  B2bButton,
  B2bIcon,
  B2bScrollableContainer,
  B2bTable,
  B2bTableCell,
  B2bTableHeader,
  B2bTableRow, B2bTableRowgroup
} from "@otto-de/b2b-react-components";
import React from "react";

export default function PartnerVariationTable({ data, displayJson }) {

  return (
      <div>
        <B2bScrollableContainer>
          <B2bTable>
            <B2bTableRowgroup type="header">
              <B2bTableRow highlight="false">
                <B2bTableHeader>Partner Variation Id</B2bTableHeader>
                <B2bTableHeader>Partner Id</B2bTableHeader>
                <B2bTableHeader>Delivery Model</B2bTableHeader>
                <B2bTableHeader>Stock Model Id</B2bTableHeader>
                <B2bTableHeader>Last Transferred Quantity</B2bTableHeader>
                <B2bTableHeader>Content</B2bTableHeader>
              </B2bTableRow>
            </B2bTableRowgroup>
            <B2bTableRowgroup type="body">
              <B2bTableRow highlight="false">
                <B2bTableCell data-testid="pv_id">{data.partnerVariationId}</B2bTableCell>
                <B2bTableCell data-testid="partner_id">{data.partnerId}</B2bTableCell>
                <B2bTableCell data-testid="delivery_model">{data.currentDeliveryModel}</B2bTableCell>
                <B2bTableCell data-testid="stock_model_id">{data.stockModelId}</B2bTableCell>
                <B2bTableCell data-testid="last_transferred_quantity">{data.lastTransferredQuantity}</B2bTableCell>
                <B2bTableCell style={{textAlign: "center"}}>
                  <B2bButton variant="secondary" onClick={displayJson} data-testid="show_json_button">
                    <B2bIcon slot="start" icon="b2b_icon-code-management"
                             size="100"/>
                  </B2bButton>
                </B2bTableCell>
              </B2bTableRow>
            </B2bTableRowgroup>
          </B2bTable>
        </B2bScrollableContainer>
      </div>
  )
}