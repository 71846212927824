import axios from "axios";

export const fetchData = (searchBy, searchStr) => {
  return axios
		.post(
			"/api/analysis/v1/info",
			{ type: searchBy, id: searchStr },
			{
        headers: {"Content-Type": "application/json"},
      }
  )
  .then((response) => {
    if (response.data) {
      return response.data;
    }
    throw new Error("No data present");
  });
}
