import {
  B2bButton,
  B2bIcon,
  B2bScrollableContainer,
  B2bTable,
  B2bTableCell,
  B2bTableHeader,
  B2bTableRow, B2bTableRowgroup
} from "@otto-de/b2b-react-components";
import React from "react";

export default function FulfillmentOrderTable({ data, displayJson }) {

  return (
		<div>
			<B2bScrollableContainer>
				<B2bTable>
					<B2bTableRowgroup type="header">
						<B2bTableRow highlight="false">
							<B2bTableHeader>Fulfillment Order Id</B2bTableHeader>
							<B2bTableHeader>Order Date</B2bTableHeader>
							<B2bTableHeader>Partner Variation Id</B2bTableHeader>
							<B2bTableHeader>Sales Order Id</B2bTableHeader>
							<B2bTableHeader>Delivery Model</B2bTableHeader>
							<B2bTableHeader>Status</B2bTableHeader>
							<B2bTableHeader>Content</B2bTableHeader>
						</B2bTableRow>
					</B2bTableRowgroup>
					<B2bTableRowgroup type="body">
						<B2bTableRow highlight="false">
							<B2bTableCell data-testid="fulfillment_order_id">
								{data.fulfillmentOrderId}
							</B2bTableCell>
							<B2bTableCell>{data.orderDate}</B2bTableCell>
							<B2bTableCell data-testid="fulfillment_order_pvid">
								{data.partnerVariationId}
							</B2bTableCell>
							<B2bTableCell>{data.salesOrderId}</B2bTableCell>
							<B2bTableCell>{data.deliveryModel}</B2bTableCell>
							<B2bTableCell data-testid="fulfillment_order_status">
								{data.status}
							</B2bTableCell>
							<B2bTableCell style={{ textAlign: "center" }}>
								<B2bButton variant="secondary" onClick={displayJson} data-testid="show_json_button">
									<B2bIcon
										slot="start"
										icon="b2b_icon-code-management"
										size="100"
									/>
								</B2bButton>
							</B2bTableCell>
						</B2bTableRow>
					</B2bTableRowgroup>
				</B2bTable>
			</B2bScrollableContainer>
		</div>
	);
}