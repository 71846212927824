import {
  B2bButton,
  B2bIcon,
  B2bScrollableContainer,
  B2bTable,
  B2bTableCell,
  B2bTableHeader,
  B2bTableRow, B2bTableRowgroup
} from "@otto-de/b2b-react-components";
import React from "react";

export default function BGFOTable({ data, displayJson }) {
  return (
      <div>
        <B2bScrollableContainer>
          <B2bTable>
            <B2bTableRowgroup type="header">
              <B2bTableRow highlight="false">
                <B2bTableHeader>BGFO ID</B2bTableHeader>
                <B2bTableHeader>Delivery Model</B2bTableHeader>
                <B2bTableHeader>Status</B2bTableHeader>
                <B2bTableHeader>Warehouse</B2bTableHeader>
                {data.latestTransferTime &&
                    <B2bTableHeader>Latest Transfer Time</B2bTableHeader>}
                {data.carrier &&
                    <B2bTableHeader>Carrier</B2bTableHeader> }
                {data.deliveryDate &&
                    <B2bTableHeader>Delivery Date</B2bTableHeader> }
                <B2bTableHeader>Content</B2bTableHeader>
              </B2bTableRow>
            </B2bTableRowgroup>
            <B2bTableRowgroup type="body">
              <B2bTableRow highlight="false">
                <B2bTableCell data-testid="bgfo_id">{data.bulkyGoodsFulfillmentOrderId}</B2bTableCell>
                <B2bTableCell data-testid="delivery_model">{data.deliveryModel}</B2bTableCell>
                <B2bTableCell data-testid="status">{data.status}</B2bTableCell>
                <B2bTableCell data-testid="warehouse">{data.warehouse}</B2bTableCell>
                {data.latestTransferTime &&
                    <B2bTableCell>{data.latestTransferTime}</B2bTableCell>}
                {data.carrier &&
                    <B2bTableCell>{data.carrier}</B2bTableCell>}
                {data.deliveryDate &&
                    <B2bTableCell>{data.deliveryDate}</B2bTableCell>}
                <B2bTableCell style={{textAlign: "center"}}>
                  <B2bButton variant="secondary" onClick={displayJson} data-testid="show_json_button">
                    <B2bIcon slot="start" icon="b2b_icon-code-management"
                             size="100"/>
                  </B2bButton>
                </B2bTableCell>
              </B2bTableRow>
            </B2bTableRowgroup>
          </B2bTable>
        </B2bScrollableContainer>
      </div>
  )
}