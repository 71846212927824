import "@otto-de/b2b-core-components/dist/b2b-core-components/b2b-core-components.css";
import {
	B2bButton,
	B2bDropdown,
	B2bIcon,
	B2bInput,
	B2bModal,
	B2bSpinner,
} from "@otto-de/b2b-react-components";
import React, { useEffect, useState } from "react";
import "./App.css";
import { fetchData } from "./api/backend";
import FulfillmentOrderTable from "./components/FulfillmentOrderTable";
import Error from "./components/Error";
import WFFOTable from "./components/WFFOTable";
import BGFOTable from "./components/BGFOTable";
import PartnerVariationTable from "./components/PartnerVariationTable";

function App() {
	const [inputId, setInputId] = useState("");
	const [inputType, setInputType] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [showTable, setShowTable] = useState(false);
	const [showButton, setShowButton] = useState(false);
	const [error, setError] = useState(false);

	const [loading, setLoading] = useState(false);
	const [isInputIdValid, setIsInputIdValid] = useState(true);
	const [responseData, setResponseData] = useState({});

	useEffect(() => {
		setShowButton(inputType && inputId);
		setError(false)
	}, [inputId, inputType]);

	const updateInputId = (e) => {
		setInputId(e.detail.value);
	};

	const updateInputType = (e) => {
		setResponseData({})
		setShowTable(false)
		setInputType(e.detail);
	};

	const fetchDataFromBackend = async () => {
		setLoading(true);
		setResponseData({});
		setShowTable(false);
		setError(false);
		try {
			let response = await fetchData(inputType, inputId);
			setResponseData(response);
			setShowTable(true);
		}
		catch {
			setError(true);
		}
		finally {
			setLoading(false);
		}
	};

	const displayJson = () => {
		setShowModal(true);
	};

	const closeModal = () => {
		setShowModal(false);
	};

	const validateInputId = () => {
		if (inputId.length > 0) {
			setIsInputIdValid(true);
		} else {
			setIsInputIdValid(false);
		}
	};

	const renderComponent = (inputType) => {
		switch(inputType) {
			case "salesOrderPositionItemId":
				return <FulfillmentOrderTable
						data={responseData}
						displayJson={displayJson}
				/>
			case "warehouseFulfillmentOrderId":
				return <WFFOTable
						data={responseData}
						displayJson={displayJson}
				/>
			case "bulkyGoodsFulfillmentOrderId":
				return <BGFOTable
						data={responseData}
						displayJson={displayJson}
				/>
			case "partnerVariationId":
				return <PartnerVariationTable
						data={responseData}
						displayJson={displayJson}
				/>
			default:
				return <div>default</div>
		}
	}

	return (
		<div className="App">
			<div className="search_bar">
				<B2bInput
					data-testid="search_input_id"
					type="text"
					value={inputId}
					onB2b-input={updateInputId}
					className="search_input"
					required="true"
					onB2b-blur={validateInputId}
					invalid={!isInputIdValid}
					error="This Field is Mandatory"
				/>
				<span slot="end">
					<B2bDropdown
						onB2b-change={updateInputType}
						data-testid="search_input_type"
					>
						<option value="">Please Select</option>
						<option value="partnerVariationId">Partner Variation Id</option>
						<option value="salesOrderPositionItemId">
							Sales Order Position Item Id
						</option>
						<option value="warehouseFulfillmentOrderId">
							Warehouse Fulfillment Order Id
						</option>
						<option value="bulkyGoodsFulfillmentOrderId">
							Bulky Goods Fulfillment Order Id
						</option>
					</B2bDropdown>
				</span>
				<B2bButton
					data-testid="search_button"
					variant="primary"
					size="100"
					disabled={!showButton}
					loading="false"
					type="button"
					class="search_button"
					onClick={fetchDataFromBackend}
				>
					<B2bIcon slot="start" icon="b2b_icon-search" size="100" />
				</B2bButton>
			</div>

			{loading ? (
				<B2bSpinner data-testid="spinner" size="100" color="primary" />
			) : (
				showTable && renderComponent(inputType)
			)}

			{error && <Error inputId={inputId} />}

			<B2bModal
				heading="Json View"
				id="modal"
				opened={showModal}
				variant="large"
				backdrop-dismiss="true"
				esc-dismiss="true"
				onB2b-close={closeModal}
			>
				<div className="search_content">
					<pre data-testid="json_view">
						{JSON.stringify(responseData, null, 2)}
					</pre>
				</div>
			</B2bModal>
		</div>
	);
}

export default App;
