import "./Error.css";

export default function Error({inputId}) {

  return (
      <div className="error">
        <b2b-headline size="400" align="center">
          No records were found
        </b2b-headline>
        <div className="error_message">
          <b2b-headline size="200" align="center">
            Searching for "{inputId}" returns no results.
          </b2b-headline>
        </div>
      </div>
  )

}